import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-cabinet',
  templateUrl: './file-cabinet.component.html',
  styleUrls: ['./file-cabinet.component.css']
})
export class FileCabinetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
