import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allocations-transfer',
  templateUrl: './allocations-transfer.component.html',
  styleUrls: ['./allocations-transfer.component.css']
})
export class AllocationsTransferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
