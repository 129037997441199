import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-style-guide',
  templateUrl: './live-style-guide.component.html',
  styleUrls: ['./live-style-guide.component.css']
})

export class LiveStyleGuideComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}


	/*
	this.fiscalYearModel = [

	]
	*/

	fiscalYearModel : any = "2019";
	fiscalYearModel_values: any = ["2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008", "2007", "2006"];


	states = [
		{name: 'Arizona', abbrev: 'AZ'},
		{name: 'California', abbrev: 'CA'},
		{name: 'Colorado', abbrev: 'CO'},
		{name: 'New York', abbrev: 'NY'},
		{name: 'Pennsylvania', abbrev: 'PA'},
	];


	months = ["January", "February", "March", "April",
	"May", "June", "July", "August", "September",
	"October", "November", "December"];

	isavailable = false;

	myClickFunction(event) {
		this.isavailable = false;
	}
	changemonths(event) {
		alert("Changed month from the Dropdown");
		console.log(event);
	}

}




@Component({
        selector: 'app-live-style-guide-route-wrapper',
        templateUrl: './live-style-guide-route-wrapper.html'
})

export class LiveStyleGuideRouteWrapper implements OnInit {

        constructor() {}
        ngOnInit() {}
}
