import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-re-org',
  templateUrl: './re-org.component.html',
  styleUrls: ['./re-org.component.css']
})
export class ReOrgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
