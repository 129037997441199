import { Component, OnInit } from '@angular/core';


import { ProjectInterface } from '../../../../../project.interface';
import { GetProjectsService } from '../../../../../get-projects.service';


@Component({
	selector: 'app-spend-plan-details',
	templateUrl: './spend-plan-details.component.html',
	styleUrls: ['./spend-plan-details.component.scss']
})
export class SpendPlanDetailsComponent implements OnInit {


	selectedProject: ProjectInterface;
	projectsMockData: ProjectInterface[];


	/*
	myArrayWithObjects = [
		{
			myName: 'justin',
			myId: '12'
		},
		{
			myName: 'jennifer',
			myId: '13'
		},
		{
			myName: 'adrian',
			myId: '14'
		},
		{
			myName: 'abigail',
			myId: '15'
		}
	];

	myArrayWithObjectsRef = this.myArrayWithObjects.findIndex( x => x.myName ==="adrian" );
	*/

	// This can be any number, it's just a way to initialize the variable.
	projectsMockDataHardSelectedProjectIndex = 9;

	// Variable initialization for the data of the hard selected project
	projectsMockDataHardSelectedProjectData = {};


	constructor( private getProjectsService: GetProjectsService ) {}


	ngOnInit() {
		this.getProjectsFromGetProjectsService();

		//console.log(this.myArrayWithObjects);
		//console.log(this.myArrayWithObjectsRef);

		// Lets get an index reference to a specific project
		// "1042 - Lockheed SR-71 Blackbird"
		this.projectsMockDataHardSelectedProjectIndex = this.projectsMockData.findIndex( p => p.pin ==="1042 - Lockheed SR-71 Blackbird" );
		console.log(this.projectsMockDataHardSelectedProjectIndex);


		console.log("Outputing the hard selected project: START");
		console.log(this.projectsMockData[this.projectsMockDataHardSelectedProjectIndex]);
		console.log("Outputing the hard selected project: END");


		this.projectsMockDataHardSelectedProjectData = this.projectsMockData[this.projectsMockDataHardSelectedProjectIndex];





	}


	// Here we are finding out what hero was selected and storing it in selectedHero
	onSelect(project: ProjectInterface): void {
		this.selectedProject = project;
		console.log(this.selectedProject);
		console.log(this.selectedProject.pin);
	}


	// Create a function to retrieve the heroes from the service.
	getProjectsFromGetProjectsService(): void {
		this.projectsMockData = this.getProjectsService.getProjects();
		console.log(this.getProjectsService.getProjects());

	}

}





@Component({
        selector: 'spend-plan-details-route-wrapper',
        templateUrl: './spend-plan-details-route-wrapper.html'
})

export class SpendPlanDetailsRouteWrapper implements OnInit {

        constructor() {}
        ngOnInit() {}
}
