import {Component} from '@angular/core';

/**
* @title Basic expansion panel
*/
@Component({
	selector: 'expansion-overview-example',
	templateUrl: 'expansion-overview-example.html',
	styleUrls: ['expansion-overview-example.css'],
})
export class ExpansionOverviewExample {

	isDisabled = true;

	panelOpenState = false;

	step = 0;

	setStep(index: number) {
		this.step = index;
	}

	nextStep() {
		this.step++;
	}

	prevStep() {
		this.step--;
	}

}
