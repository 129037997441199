import { Component } from '@angular/core';

//import { ProjectInterface } from './project.interface';
//import { PROJECTS } from './mock-projects';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})


export class AppComponent {

	//myProjects = PROJECTS;

	constructor() {
		//console.log(this.myProjects);
	}

	title = 'cerms-live-style-guide';



}
