import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface DialogData {
  theAllocationAmount: string;
  name: string;
}

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'allocation-form',
  templateUrl: 'allocation-form.html',
  styleUrls: ['allocation-form.css'],
})
export class AllocationForm implements OnInit {


        @Output() allocationEntered = new EventEmitter();

        emitAllocationEntered(e) {
                console.log(e);
                this.allocationEntered.emit(e);
        }

        //htmlStr: string = '<strong>The Tortoise</strong> &amp; the Hare';
        //seconds: any;

        //_78 = true;

        addAllocationEntryToTable = false;

        //seconds = 11;

        ngOnInit() {
                //this.seconds = "<h2>Hi there</h2>";

                //this.htmlStr: string = '<strong>The Tortoise</strong> &amp; the Hare';

                //this._78 = false;
        }




  theAllocationAmount: string;
  name: string;

  constructor(public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(AllocationFormDialog, {
            id: "AllocationFormDialogID",
      width: '550px',
      data: {name: this.name, theAllocationAmount: this.theAllocationAmount}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.theAllocationAmount = result;

      this.addAllocationEntryToTable = true;



      if (this.theAllocationAmount==undefined) {

              this.addAllocationEntryToTable = false;

      } else {

      }


    });





  }

}





@Component({
  selector: 'allocation-form-dialog',
  templateUrl: 'allocation-form-dialog.html',
})





export class AllocationFormDialog {


        @Output() allocationEntered = new EventEmitter();

        emitAllocationEntered(e) {
                console.log(e);
                this.allocationEntered.emit(e);
        }


  constructor(
    public dialogRef: MatDialogRef<AllocationFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
