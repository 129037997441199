import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import { ActivatedRoute, Routes, Router, RouterModule } from '@angular/router';

import { MatAutocompleteSelectedEvent } from '@angular/material';

/**
* @title Simple autocomplete
*/
@Component({
        selector: 'direct-access-autocomplete',
        templateUrl: 'direct-access-autocomplete.html',
        styleUrls: ['direct-access-autocomplete.css'],
})

export class DirectAccessAutocomplete {

	constructor( private route:ActivatedRoute, private router:Router ) { }

        myControl = new FormControl();
        options: string[] = [
				'1029 - Unfunded',
                                '1030 - unf2',
                                '1031 - Studies for Air Force x',
                                '1032 - Front Office Support',
                                '1033 - CERMS Database',
                                '1034 - Add FFRDC',
                                '1035 - Navy Facility',
                                '1036 - Navy Federal',
                                '1037 - Mission Critical',
				'1041 - North American X-15'
                        ];

	/*
	goToPage() {
		console.log("Going to another page");

		console.log();
		this.router.navigate(['configuration-examples/budget/spend-plan/spend-plan-details']);

	}
	*/


	onSelectionChanged(event: MatAutocompleteSelectedEvent) {
		console.log(event.option.value);

		if (event.option.value == "1041 - North American X-15") {
			//console.log("we are getting close");
			this.router.navigate(['configuration-examples/budget/spend-plan/spend-plan-details']);
		}
	}
}
