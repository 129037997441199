import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';


// Router
import { AppRoutingModule, routingComponents } from './app-routing.module';


// Entire library of Angular Material components
import { MaterialComponentsModule } from './material-modules/material-components.module';

// Example Material components
import { ComponentExamplesModule } from './component-examples/component-examples.module';


// Common components of the app of the style guide
import { StyleGuideTopTabTagNav } from './common-components/style-guide-top-tab-tag-nav/style-guide-top-tab-tag-nav';





// Common components of the configuration examples ( Needs to be packaged with the module below )
import { ConfigExampleMainNavComponent } from './configuration-examples-common-components/config-example-main-nav/config-example-main-nav.component';

// Configuration Examples Common Components (All of them)
import { ConfigurationExamplesCommonComponentsModule } from './configuration-examples-common-components/configuration-examples-common-components.module';



// Pages: START
import { LiveStyleGuideComponent } from './pages/live-style-guide/live-style-guide.component';
import { ConfigurationExamplesLandingPageComponent } from './pages/configuration-examples-landing-page/configuration-examples-landing-page.component';

import { BudgetComponent } from './pages/configuration-examples/budget/budget.component';
	import { AllocationOfResourcesComponent } from './pages/configuration-examples/budget/allocation-of-resources/allocation-of-resources.component';

	import { AllocationForm, AllocationFormDialog } from './pages/configuration-examples/budget/allocation-of-resources/allocation-form/allocation-form';

	import { AllocationsTransferComponent } from './pages/configuration-examples/budget/allocations-transfer/allocations-transfer.component';



	import { BudgetReportsComponent } from './pages/configuration-examples/budget/budget-reports/budget-reports.component';
		import { GenerateSpendPlanReportComponent } from './pages/configuration-examples/budget/budget-reports/generate-spend-plan-report/generate-spend-plan-report.component';


	import { SpendPlanComponent } from './pages/configuration-examples/budget/spend-plan/spend-plan.component';
		import { SpendPlanDetailsComponent } from './pages/configuration-examples/budget/spend-plan/spend-plan-details/spend-plan-details.component';


	import { ExecutionComponent } from './pages/configuration-examples/budget/execution/execution.component';
	import { UlosComponent } from './pages/configuration-examples/budget/ulos/ulos.component';

//import { ContractsComponent } from './pages/configuration-examples/contracts/contracts.component';
	import { HowDoIComponent } from './pages/configuration-examples/contracts/how-do-i/how-do-i.component';
	import { CorDataComponent } from './pages/configuration-examples/contracts/cor-data/cor-data.component';
	import { ContctFileCabinetComponent } from './pages/configuration-examples/contracts/contct-file-cabinet/contct-file-cabinet.component';

import { AdminComponent } from './pages/configuration-examples/admin/admin.component';
	import { DataAdminComponent } from './pages/configuration-examples/admin/data-admin/data-admin.component';
	import { UsersComponent } from './pages/configuration-examples/admin/users/users.component';
	import { ReOrgComponent } from './pages/configuration-examples/admin/re-org/re-org.component';

import { FfrdcComponent } from './pages/configuration-examples/ffrdc/ffrdc.component';
	import { AllocationComponent } from './pages/configuration-examples/ffrdc/allocation/allocation.component';
	import { ContractorsComponent } from './pages/configuration-examples/ffrdc/contractors/contractors.component';
	import { ReportsComponent } from './pages/configuration-examples/ffrdc/reports/reports.component';







// Pages: END




//import { ProjectInterface } from './project.interface';
//import { PROJECTS } from './mock-projects';



@NgModule({
	declarations: [
		AppComponent,
		routingComponents,
		ConfigExampleMainNavComponent,
		StyleGuideTopTabTagNav,
		LiveStyleGuideComponent,
		ConfigurationExamplesLandingPageComponent,
		BudgetComponent,
		AllocationOfResourcesComponent,
		AllocationsTransferComponent,
		SpendPlanComponent,
		ExecutionComponent,
		UlosComponent,
		HowDoIComponent,
		CorDataComponent,
		ContctFileCabinetComponent,
		AdminComponent,
		DataAdminComponent,
		UsersComponent,
		ReOrgComponent,
		FfrdcComponent,
		AllocationComponent,
		ContractorsComponent,
		ReportsComponent,
		AllocationForm,
		AllocationFormDialog,
		SpendPlanDetailsComponent,
		BudgetReportsComponent,
		GenerateSpendPlanReportComponent
	],
	imports: [
		BrowserModule,
		MaterialComponentsModule,
		ComponentExamplesModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		ConfigurationExamplesCommonComponentsModule
	],
	providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
	bootstrap: [
		AppComponent
	],
	entryComponents: [
		AllocationFormDialog
	]
})

export class AppModule { }
