import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-budget-reports',
	templateUrl: './budget-reports.component.html',
	styleUrls: ['./budget-reports.component.css']
})
export class BudgetReportsComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}



@Component({
        selector: 'budget-reports-route-wrapper',
        templateUrl: './budget-reports-route-wrapper.html'
})

export class BudgetReportsRouteWrapper implements OnInit {

        constructor() {}
        ngOnInit() {}
}
