import { Injectable } from '@angular/core';

import { ProjectInterface } from './project.interface';
import { PROJECTS } from './mock-projects';

@Injectable({
	providedIn: 'root'
})


export class GetProjectsService {

	constructor() { }


	getProjects() {
		return PROJECTS;
	}

}
