import {Component} from '@angular/core';

/**
 * @title Nested menu
 */
@Component({
  selector: 'nested-menu-example',
  templateUrl: 'nested-menu-example.html',
  styleUrls: ['nested-menu-example.css'],
})
export class NestedMenuExample {}