import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-generate-spend-plan-report',
	templateUrl: './generate-spend-plan-report.component.html',
	styleUrls: ['./generate-spend-plan-report.component.scss']
})
export class GenerateSpendPlanReportComponent implements OnInit {






	constructor() {
	}

	/*
	toppings = new FormControl();


	toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
	*/


	oprItems = new FormControl();

	oprItemsList: string[] = [
		'Management Reserve',
		'CI, Security & LE',
		'Collections & Sensitive Activities',
		'Direct Report Offices'
	];


	appropriationItems = new FormControl();

	appropriationItemsList: string[] = [
		'O&M',
		'RDT&E'
	];




	ngOnInit() {
	}

}



@Component({
        selector: 'generate-spend-plan-report-route-wrapper',
        templateUrl: './generate-spend-plan-report-route-wrapper.html'
})

export class GenerateSpendPlanReportRouteWrapper implements OnInit {

        constructor() {}
        ngOnInit() {}
}
