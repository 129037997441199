import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ulos',
  templateUrl: './ulos.component.html',
  styleUrls: ['./ulos.component.css']
})
export class UlosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
