import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-execution',
	templateUrl: './execution.component.html',
	styleUrls: ['./execution.component.css']
})
export class ExecutionComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}



	  step = 0;

	  setStep(index: number) {
	    this.step = index;
	  }

	  nextStep() {
	    this.step++;
	  }

	  prevStep() {
	    this.step--;
	  }

}



@Component({
        selector: 'execution-route-wrapper',
        templateUrl: './execution-route-wrapper.html'
})

export class ExecutionRouteWrapper implements OnInit {


        constructor() {}
        ngOnInit() {}

}
