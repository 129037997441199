import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Entire library of Angular Material components
import { MaterialComponentsModule } from '../material-modules/material-components.module';


// Configuration example common commponents
import { DirectAccessAutocomplete } from './direct-access-autocomplete/direct-access-autocomplete';

import { SpendPlanFundedTable } from './spend-plan-funded-table/spend-plan-funded-table';


@NgModule({
	declarations: [
                DirectAccessAutocomplete,
		SpendPlanFundedTable
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FormsModule,
		MaterialComponentsModule
	],
	exports: [
		CommonModule,
		DirectAccessAutocomplete,
		SpendPlanFundedTable
	]
})


export class ConfigurationExamplesCommonComponentsModule { }
