import { Component, OnInit } from '@angular/core';

@Component({
        selector: 'app-spend-plan',
        templateUrl: './spend-plan.component.html',
        styleUrls: ['./spend-plan.component.css']
})
export class SpendPlanComponent implements OnInit {

        constructor() { }


        fiscalYearModel: any = "";
	fiscalYearModel_values: any = ["2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008", "2007", "2006"];

	appropriationModel: any = "";
	oprModel: any = "";
	directorModel: any = "";
	programModel: any = "";


	// Fiscal Years
	_2006 = false;
	_2007 = false;
	_2008 = false;
	_2009 = false;
	_2010 = false;
	_2011 = false;
	_2012 = false;
	_2013 = false;
	_2014 = false;
	_2015 = false;
	_2016 = false;
	_2017 = false;
	_2018 = false;
	_2019 = false;

	// Appropriations
	qm = false;
	rdtm = false;
	procurement = false;

	// OPR
	managementReserve = false;
	admin = false;
	ciSecurityLe = false;
	directReportOffices = false;
	intelligenceStrategyProgramsResources = false;
	organizationalManagementAndSupport = false;
	personnelDevelopmentReadiness = false;
	noResultsExample = false;

	// Director
	humanCapitalManagementOffice = false;
	atlStudyFundsOM = false;
	humanCapital = false;

	// Program
	congressionalActivities = false;
	policyGuidance = false;
	reimbursablePersonnelCosts = false;





/*

directReportOffices
intelligenceStrategyProgramsResources
organizationalManagementAndSupport
personnelDevelopmentReadiness
noResultsExample

*/

	ngOnInit() {

		if (this.fiscalYearModel == "") {
			this.fiscalYearModel = "unset";
		}

		if (this.appropriationModel == "") {
			this.appropriationModel = "unset";
		}

		if (this.oprModel == "") {
			this.oprModel = "unset";
		}


		if (this.directorModel == "") {
			this.directorModel = "unset";
		}

		if (this.programModel == "") {
			this.programModel = "unset";
		}


	}

	/*
		# Appropriations
			qm
			rdtm
			procurement

		# OPR:
			managementReserve
			admin
			ciSecurityLe

	 	# Director:
			humanCapitalManagementOffice
			atlStudyFundsOM
			humanCapital

		# Program:
			congressionalActivities
			policyGuidance
			reimbursablePersonnelCosts
	*/

	//values = "";

	changeFiscalYear (event) {

		// Appropriations
		this.qm = false;
		this.rdtm = false;
		this.procurement = false;

		// OPR
		this.managementReserve = false;
		this.admin = false;
		this.ciSecurityLe = false;

		this.directReportOffices = false;
		this.intelligenceStrategyProgramsResources = false;
		this.organizationalManagementAndSupport = false;
		this.personnelDevelopmentReadiness = false;
		this.noResultsExample = false;


		// Director
		this.humanCapitalManagementOffice = false;
		this.atlStudyFundsOM = false;
		this.humanCapital = false;

		// Program
		this.congressionalActivities = false;
		this.policyGuidance = false;
		this.reimbursablePersonnelCosts = false;



		// Reset my dependent select inputs to unselected and set them disabled
		if (this.fiscalYearModel == "") {

			/*
			this.appropriationModel = "";
			this.oprModel = "";
			this.directorModel = "";
			this.programModel = "";
			*/

			this.fiscalYearModel = "unset";
		}


		//console.log(this.fiscalYearModel);

		//this.values += event.target.value + ' | ';
		//console.log(this.values);

		console.log(event.target);





		this.appropriationModel = "";
		this.oprModel = "";
		this.directorModel = "";
		this.programModel = "";


		// You can change the selection to a specific value by doing this for example:
		// this.oprModel = "Admin";
		// Where "Admin" is an option value, like in here: <option value="Admin">Admin</option>

		if (this.fiscalYearModel == "2006") {
			this._2006 = true;
		} else {
			this._2006 = false;
		}

		if (this.fiscalYearModel == "2007") {
			this._2007 = true;
		} else {
			this._2007 = false;
		}

		if (this.fiscalYearModel == "2008") {
			this._2008 = true;
		} else {
			this._2008 = false;
		}

		if (this.fiscalYearModel == "2009") {
			this._2009 = true;
		} else {
			this._2009 = false;
		}

		if (this.fiscalYearModel == "2010") {
			this._2010 = true;
		} else {
			this._2010 = false;
		}

		if (this.fiscalYearModel == "2011") {
			this._2011 = true;
		} else {
			this._2011 = false;
		}

		if (this.fiscalYearModel == "2012") {
			this._2012 = true;
		} else {
			this._2012 = false;
		}

		if (this.fiscalYearModel == "2013") {
			this._2013 = true;
		} else {
			this._2013 = false;
		}

		if (this.fiscalYearModel == "2014") {
			this._2014 = true;
		} else {
			this._2014 = false;
		}

		if (this.fiscalYearModel == "2015") {
			this._2015 = true;
		} else {
			this._2015 = false;
		}

		if (this.fiscalYearModel == "2016") {
			this._2016 = true;
		} else {
			this._2016 = false;
		}

		if (this.fiscalYearModel == "2017") {
			this._2017 = true;
		} else {
			this._2017 = false;
		}

		if (this.fiscalYearModel == "2018") {
			this._2018 = true;
		} else {
			this._2018 = false;
		}

		if (this.fiscalYearModel == "2019") {
			this._2019 = true;
		} else {
			this._2019 = false;
		}


	}


	changeAppropriation (event) {



		// Appropriations
		/*
		this.qm = false;
		this.rdtm = false;
		this.procurement = false;
		*/

		// OPR
		this.managementReserve = false;
		this.admin = false;
		this.ciSecurityLe = false;

		this.directReportOffices = false;
		this.intelligenceStrategyProgramsResources = false;
		this.organizationalManagementAndSupport = false;
		this.personnelDevelopmentReadiness = false;
		this.noResultsExample = false;

		// Director
		this.humanCapitalManagementOffice = false;
		this.atlStudyFundsOM = false;
		this.humanCapital = false;

		// Program
		this.congressionalActivities = false;
		this.policyGuidance = false;
		this.reimbursablePersonnelCosts = false;


		// Reset my dependent select inputs to unselected and set them disabled
		if (this.appropriationModel == "") {
			this.appropriationModel = "unset";
		}

		this.oprModel = "";
		this.directorModel = "";
		this.programModel = "";

		/*
		*/

		if (this.appropriationModel == "qm") {
			this.qm = true;
		} else {
			this.qm = false;
		}


		if (this.appropriationModel == "rdtm") {
			this.rdtm = true;
		} else {
			this.rdtm = false;
		}


		if (this.appropriationModel == "procurement") {
			this.procurement = true;
		} else {
			this.procurement = false;
		}

	}


	changeOpr (event) {


		// OPR
		/*this.managementReserve = false;
		this.admin = false;
		this.ciSecurityLe = false;
		*/

		// Director
		this.humanCapitalManagementOffice = false;
		this.atlStudyFundsOM = false;
		this.humanCapital = false;

		// Program
		this.congressionalActivities = false;
		this.policyGuidance = false;
		this.reimbursablePersonnelCosts = false;


		// Reset my dependent select inputs to unselected and set them disabled
		if (this.oprModel == "") {
			this.oprModel = "unset";
		}


		this.directorModel = "";
		this.programModel = "";


		if (this.oprModel == "managementReserve") {
			this.managementReserve = true;
		} else {
			this.managementReserve = false;
		}

		if (this.oprModel == "admin") {
			this.admin = true;
		} else {
			this.admin = false;
		}


		if (this.oprModel == "ciSecurityLe") {
			this.ciSecurityLe = true;
		} else {
			this.ciSecurityLe = false;
		}


		/* */


		if (this.oprModel == "directReportOffices") {
			this.directReportOffices = true;
		} else {
			this.directReportOffices = false;
		}


		if (this.oprModel == "intelligenceStrategyProgramsResources") {
			this.intelligenceStrategyProgramsResources = true;
		} else {
			this.intelligenceStrategyProgramsResources = false;
		}


		if (this.oprModel == "organizationalManagementAndSupport") {
			this.organizationalManagementAndSupport = true;
		} else {
			this.organizationalManagementAndSupport = false;
		}


		if (this.oprModel == "personnelDevelopmentReadiness") {
			this.personnelDevelopmentReadiness = true;
		} else {
			this.personnelDevelopmentReadiness = false;
		}

		if (this.oprModel == "noResultsExample") {
			this.noResultsExample = true;
		} else {
			this.noResultsExample = false;
		}


		/* */


		/*
			# OPR:
				managementReserve
				admin
				ciSecurityLe
		*/

	}

	changeDirector (event) {



		// Director
		/*
		this.humanCapitalManagementOffice = false;
		this.atlStudyFundsOM = false;
		this.humanCapital = false;
		*/

		// Program
		this.congressionalActivities = false;
		this.policyGuidance = false;
		this.reimbursablePersonnelCosts = false;


		// Reset my dependent select inputs to unselected and set them disabled
		if (this.directorModel == "") {
			this.directorModel = "unset";
		}



		this.programModel = "";



		if (this.directorModel == "humanCapitalManagementOffice") {
			this.humanCapitalManagementOffice = true;
		} else {
			this.humanCapitalManagementOffice = false;
		}

		if (this.directorModel == "atlStudyFundsOM") {
			this.atlStudyFundsOM = true;
		} else {
			this.atlStudyFundsOM = false;
		}

		if (this.directorModel == "humanCapital") {
			this.humanCapital = true;
		} else {
			this.humanCapital = false;
		}



		/*
			# Appropriations
				qm
				rdtm
				procurement

			# OPR:
				managementReserve
				admin
				ciSecurityLe

		 	# Director:
				humanCapitalManagementOffice
				atlStudyFundsOM
				humanCapital

			# Program:
				congressionalActivities
				policyGuidance
				reimbursablePersonnelCosts
		*/

	}


	changeProgram (event) {




		// Program
		/*
		this.congressionalActivities = false;
		this.policyGuidance = false;
		this.reimbursablePersonnelCosts = false;
		*/

		// Reset my dependent select inputs to unselected and set them disabled
		if (this.programModel == "") {
			this.programModel = "unset";
		}


		if (this.programModel == "congressionalActivities") {
			this.congressionalActivities = true;
		} else {
			this.congressionalActivities = false;
		}


		if (this.programModel == "policyGuidance") {
			this.policyGuidance = true;
		} else {
			this.policyGuidance = false;
		}

		if (this.programModel == "reimbursablePersonnelCosts") {
			this.reimbursablePersonnelCosts = true;
		} else {
			this.reimbursablePersonnelCosts = false;
		}




	}

}



@Component({
        selector: 'spend-plan-route-wrapper',
        templateUrl: './spend-plan-route-wrapper.html'
})

export class SpendPlanRouteWrapper implements OnInit {

        constructor() {}
        ngOnInit() {}
}
