import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'style-guide-top-tab-tag-nav',
  templateUrl: './style-guide-top-tab-tag-nav.html',
  styleUrls: ['./style-guide-top-tab-tag-nav.css']
})
export class StyleGuideTopTabTagNav implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
