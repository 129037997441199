import { NgModule } from '@angular/core';
import { Routes, RouterModule, Scroll, NavigationEnd } from '@angular/router';

//import { ConfigExpMainNavModule } from './configuration-examples-common-components/config-exp-main-nav/config-exp-main-nav.module';

import { LiveStyleGuideRouteWrapper } from './pages/live-style-guide/live-style-guide.component';

import { ConfigurationExamplesLandingPageComponent } from './pages/configuration-examples-landing-page/configuration-examples-landing-page.component';

	import { BudgetComponent } from './pages/configuration-examples/budget/budget.component';


		import { AllocationOfResourcesRouteWrapper } from './pages/configuration-examples/budget/allocation-of-resources/allocation-of-resources.component';

		import { AllocationsTransferComponent } from './pages/configuration-examples/budget/allocations-transfer/allocations-transfer.component';
		import { ExecutionRouteWrapper } from './pages/configuration-examples/budget/execution/execution.component';
		import { FileCabinetComponent } from './pages/configuration-examples/budget/file-cabinet/file-cabinet.component';



		import { BudgetReportsRouteWrapper } from './pages/configuration-examples/budget/budget-reports/budget-reports.component';
			import { GenerateSpendPlanReportRouteWrapper } from './pages/configuration-examples/budget/budget-reports/generate-spend-plan-report/generate-spend-plan-report.component';


		import { SpendPlanRouteWrapper } from './pages/configuration-examples/budget/spend-plan/spend-plan.component';
			import { SpendPlanDetailsRouteWrapper } from './pages/configuration-examples/budget/spend-plan/spend-plan-details/spend-plan-details.component';


		import { UlosComponent } from './pages/configuration-examples/budget/ulos/ulos.component';

	import { ContractsComponent } from './pages/configuration-examples/contracts/contracts.component';
		import { CorDataComponent } from './pages/configuration-examples/contracts/cor-data/cor-data.component';
		import { ContctFileCabinetComponent } from './pages/configuration-examples/contracts/contct-file-cabinet/contct-file-cabinet.component';
		import { HowDoIComponent } from './pages/configuration-examples/contracts/how-do-i/how-do-i.component';

	import { TravelComponent } from './pages/configuration-examples/travel/travel.component';


const routes: Routes = [
	{ path: '', component: LiveStyleGuideRouteWrapper },
	{ path: 'configuration-examples', component: ConfigurationExamplesLandingPageComponent },
	{ path: 'configuration-examples/budget', component: BudgetComponent },
		{ path: 'configuration-examples/budget/allocation-of-resources', component: AllocationOfResourcesRouteWrapper },
		{ path: 'configuration-examples/budget/allocations-transfer', component: AllocationsTransferComponent },
		{ path: 'configuration-examples/budget/execution', component: ExecutionRouteWrapper },
		{ path: 'configuration-examples/budget/file-cabinet', component: FileCabinetComponent },


		{ path: 'configuration-examples/budget/budget-reports', component: BudgetReportsRouteWrapper },
		{ path: 'configuration-examples/budget/budget-reports/generate-spend-plan-report', component: GenerateSpendPlanReportRouteWrapper },


		{ path: 'configuration-examples/budget/spend-plan', component: SpendPlanRouteWrapper },
		{ path: 'configuration-examples/budget/spend-plan/spend-plan-details', component: SpendPlanDetailsRouteWrapper },



		{ path: 'configuration-examples/budget/ulos', component: UlosComponent },
	{ path: 'configuration-examples/contracts', component: ContractsComponent },
		{ path: 'configuration-examples/contracts/cor-data', component: CorDataComponent },
		{ path: 'configuration-examples/contracts/contct-file-cabinet', component: ContctFileCabinetComponent },
		{ path: 'configuration-examples/contracts/how-do-i', component: HowDoIComponent },
	{ path: 'configuration-examples/travel', component: TravelComponent }
];

@NgModule({
  imports: [

	RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled',
		scrollOffset: [0, -50]
	})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
	LiveStyleGuideRouteWrapper,
	ConfigurationExamplesLandingPageComponent,
		BudgetComponent,
			AllocationOfResourcesRouteWrapper,
			AllocationsTransferComponent,
			ExecutionRouteWrapper,
			FileCabinetComponent,
			BudgetReportsRouteWrapper,
				GenerateSpendPlanReportRouteWrapper,
			SpendPlanRouteWrapper,
				SpendPlanDetailsRouteWrapper,
			UlosComponent,
		ContractsComponent,
			CorDataComponent,
			ContctFileCabinetComponent,
			HowDoIComponent,
		TravelComponent
]
