import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-admin',
  templateUrl: './data-admin.component.html',
  styleUrls: ['./data-admin.component.css']
})
export class DataAdminComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
