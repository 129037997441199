import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contct-file-cabinet',
  templateUrl: './contct-file-cabinet.component.html',
  styleUrls: ['./contct-file-cabinet.component.css']
})
export class ContctFileCabinetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
