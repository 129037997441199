import { ProjectInterface } from './project.interface';

export const PROJECTS: ProjectInterface[] = [

	{
		fundedStatus: true,
		pin: "1041 - North American X-15",


		fiscalYear: "2019",
		appropriation: "O&M",
		opr: "Management Reserve",
		director: "DDS",
		program: "DITMAC",


		projectTitle: "North American X-15",
		contractor: "MITRE",
		pointOfContact: "John Doe",
		periodOfPerformance: "01/01/2019 - 12/01/2019",

		elementOfExpenseIdentificationCode: "1113",
		fundedType: "Direct Citation",
		contractLength: "Base",

		contractorfullTimeEquivalent: "1",
		contractorfullTimeEquivalentOnsite: "0.5",
		contractorfullTimeEquivalentOffsite: "0.5",

		lastFiscalYearActuallySpent: "$200,000.00",

		federallyFundedResearchAndDevelopmentCenterNumber: "9NC0",
		federallyFundedResearchAndDevelopmentCenterIndicator: "STE",

		thisFiscalYearPlannedFundedAmount: "$400,000.00",
		thisFiscalYearCommittedFundedAmount: "$300,000.00",

		thisFiscalYearCommittedFundedAmountPercent: "75%",

		projectType: "Continuation from Last Year",

		nextYearCostEstimate: "425,000.00",

		estimateObligationDate: "07/20/2019",

		effortGroup: "COMM IT",

		contractingOfficersRepresentative: "Officer Reed",
		contractNumber: "HQX-89-K-056",

		taskOrderNumber: "21",

		receivingAgencyDepartmentOfDefenseActivityAddressCode: "AFRL",

		projectDescription: "Procurement of new military jets",

		projectDeliverables: "200 new Blackbird military jets"
	},
	{
		fundedStatus: true,
		pin: "1042 - Lockheed SR-71 Blackbird",


		fiscalYear: "2019",
		appropriation: "O&M",
		opr: "Management Reserve",
		director: "DDS",
		program: "DITMAC",


		projectTitle: "Lockheed SR-71 Blackbird",
		contractor: "MITRE",
		pointOfContact: "John Doe",
		periodOfPerformance: "01/01/2019 - 12/01/2019",

		elementOfExpenseIdentificationCode: "1113",
		fundedType: "Direct Citation",
		contractLength: "Base",

		contractorfullTimeEquivalent: "1",
		contractorfullTimeEquivalentOnsite: "0.5",
		contractorfullTimeEquivalentOffsite: "0.5",

		lastFiscalYearActuallySpent: "$200,000.00",

		federallyFundedResearchAndDevelopmentCenterNumber: "9NC0",
		federallyFundedResearchAndDevelopmentCenterIndicator: "STE",

		thisFiscalYearPlannedFundedAmount: "$400,000.00",
		thisFiscalYearCommittedFundedAmount: "$300,000.00",

		thisFiscalYearCommittedFundedAmountPercent: "75%",

		projectType: "Continuation from Last Year",

		nextYearCostEstimate: "425,000.00",

		estimateObligationDate: "07/20/2019",

		effortGroup: "COMM IT",

		contractingOfficersRepresentative: "Officer Reed",
		contractNumber: "HQX-89-K-056",

		taskOrderNumber: "21",

		receivingAgencyDepartmentOfDefenseActivityAddressCode: "AFRL",

		projectDescription: "Procurement of new military jets",

		projectDeliverables: "200 new Blackbird military jets"
	},
	{
		fundedStatus: true,
		pin: "1043 - Lockheed YF-12",


		fiscalYear: "2019",
		appropriation: "O&M",
		opr: "Management Reserve",
		director: "DDS",
		program: "DITMAC",


		projectTitle: "Lockheed YF-12",
		contractor: "MITRE",
		pointOfContact: "John Doe",
		periodOfPerformance: "01/01/2019 - 12/01/2019",

		elementOfExpenseIdentificationCode: "1113",
		fundedType: "Direct Citation",
		contractLength: "Base",

		contractorfullTimeEquivalent: "1",
		contractorfullTimeEquivalentOnsite: "0.5",
		contractorfullTimeEquivalentOffsite: "0.5",

		lastFiscalYearActuallySpent: "$200,000.00",

		federallyFundedResearchAndDevelopmentCenterNumber: "9NC0",
		federallyFundedResearchAndDevelopmentCenterIndicator: "STE",

		thisFiscalYearPlannedFundedAmount: "$400,000.00",
		thisFiscalYearCommittedFundedAmount: "$300,000.00",

		thisFiscalYearCommittedFundedAmountPercent: "75%",

		projectType: "Continuation from Last Year",

		nextYearCostEstimate: "425,000.00",

		estimateObligationDate: "07/20/2019",

		effortGroup: "COMM IT",

		contractingOfficersRepresentative: "Officer Reed",
		contractNumber: "HQX-89-K-056",

		taskOrderNumber: "21",

		receivingAgencyDepartmentOfDefenseActivityAddressCode: "AFRL",

		projectDescription: "Procurement of new military jets",

		projectDeliverables: "200 new Blackbird military jets"
	},
	{
		fundedStatus: true,
		pin: "1044 - Mikoyan MiG-25 Foxbat",


		fiscalYear: "2019",
		appropriation: "O&M",
		opr: "Management Reserve",
		director: "DDS",
		program: "DITMAC",


		projectTitle: "Mikoyan MiG-25 Foxbat",
		contractor: "MITRE",
		pointOfContact: "John Doe",
		periodOfPerformance: "01/01/2019 - 12/01/2019",

		elementOfExpenseIdentificationCode: "1113",
		fundedType: "Direct Citation",
		contractLength: "Base",

		contractorfullTimeEquivalent: "1",
		contractorfullTimeEquivalentOnsite: "0.5",
		contractorfullTimeEquivalentOffsite: "0.5",

		lastFiscalYearActuallySpent: "$200,000.00",

		federallyFundedResearchAndDevelopmentCenterNumber: "9NC0",
		federallyFundedResearchAndDevelopmentCenterIndicator: "STE",

		thisFiscalYearPlannedFundedAmount: "$400,000.00",
		thisFiscalYearCommittedFundedAmount: "$300,000.00",

		thisFiscalYearCommittedFundedAmountPercent: "75%",

		projectType: "Continuation from Last Year",

		nextYearCostEstimate: "425,000.00",

		estimateObligationDate: "07/20/2019",

		effortGroup: "COMM IT",

		contractingOfficersRepresentative: "Officer Reed",
		contractNumber: "HQX-89-K-056",

		taskOrderNumber: "21",

		receivingAgencyDepartmentOfDefenseActivityAddressCode: "AFRL",

		projectDescription: "Procurement of new military jets",

		projectDeliverables: "200 new Blackbird military jets"
	},
	{
		fundedStatus: true,
		pin: "1045 - Bell X-2 Starbuster",


		fiscalYear: "2019",
		appropriation: "O&M",
		opr: "Management Reserve",
		director: "DDS",
		program: "DITMAC",


		projectTitle: "Bell X-2 Starbuster",
		contractor: "MITRE",
		pointOfContact: "John Doe",
		periodOfPerformance: "01/01/2019 - 12/01/2019",

		elementOfExpenseIdentificationCode: "1113",
		fundedType: "Direct Citation",
		contractLength: "Base",

		contractorfullTimeEquivalent: "1",
		contractorfullTimeEquivalentOnsite: "0.5",
		contractorfullTimeEquivalentOffsite: "0.5",

		lastFiscalYearActuallySpent: "$200,000.00",

		federallyFundedResearchAndDevelopmentCenterNumber: "9NC0",
		federallyFundedResearchAndDevelopmentCenterIndicator: "STE",

		thisFiscalYearPlannedFundedAmount: "$400,000.00",
		thisFiscalYearCommittedFundedAmount: "$300,000.00",

		thisFiscalYearCommittedFundedAmountPercent: "75%",

		projectType: "Continuation from Last Year",

		nextYearCostEstimate: "425,000.00",

		estimateObligationDate: "07/20/2019",

		effortGroup: "COMM IT",

		contractingOfficersRepresentative: "Officer Reed",
		contractNumber: "HQX-89-K-056",

		taskOrderNumber: "21",

		receivingAgencyDepartmentOfDefenseActivityAddressCode: "AFRL",

		projectDescription: "Procurement of new military jets",

		projectDeliverables: "200 new Blackbird military jets"
	}


];
